import { Link } from "gatsby"
import React, { Component } from "react"
import HeaderCss from "./header.module.css"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fondo: "transparent",
      letras: "#fff",
    }
  }

  handleClick = () => {
    switch (this.props.siteTitle) {
      case "Home":
        if (this.state.fondo !== "transparent") {
          this.setState({
            fondo: "transparent",
            letras: "#fff",
          })
          break
        }
        this.setState({
          fondo: "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgb(12,29,64) 100%)",
          letras: "#fff",
        })
        break

      case "Desarrollo":
        if (this.state.fondo !== "transparent") {
          this.setState({
            fondo: "transparent",
            letras: "#fff",
          })
          break
        }
        this.setState({
          fondo:
            "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgb(255, 255, 255) 100%)",
          letras: "#094ED9",
        })
        break

      case "Diseño":
        if (this.state.fondo !== "transparent") {
          this.setState({
            fondo: "transparent",
            letras: "#fff",
          })
          break
        }
        this.setState({
          fondo:
            "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgb(255,255,255) 100%)",
          letras: "#CA015F",
        })
        break

      default:
        this.setState({
          fondo: "transparent",
          letras: "#fff",
        })
        break
    }
  }
  render() {
    return (
      <div className={HeaderCss.menuContainer}>
        <input
          onClick={this.handleClick}
          type="checkbox"
          className={HeaderCss.checkMenu}
        ></input>
        <span
          className={HeaderCss.lineHamburger}
          style={{ background: this.state.letras }}
        ></span>
        <span
          className={HeaderCss.lineHamburger}
          style={{ background: this.state.letras }}
        ></span>
        <span
          className={HeaderCss.lineHamburger}
          style={{ background: this.state.letras }}
        ></span>
        <div
          className={HeaderCss.menu}
          style={{ background: this.state.fondo }}
        >
          {/* <Link
            to=""
            className={HeaderCss.menuLink}
            style={{ color: this.state.letras }}
          >
            Productos
          </Link> */}
          <Link
            to="Acercade"
            className={HeaderCss.menuLink}
            style={{ color: this.state.letras }}
          >
            Acerca de
          </Link>
          <Link
            to="Tecnologias"
            className={HeaderCss.menuLink}
            style={{ color: this.state.letras }}
          >
            Tecnologías de desarrollo
          </Link>
          <a
           className={HeaderCss.menuLink}
           style={{ color: this.state.letras }}
           href="https://blog.engranecreativo.com">
            Blog
          </a>
        </div>
      </div>
    )
  }
}

export default Header
