import React, { Component } from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';

class Messenger extends Component {
    state = { 

     }
    render() { 
        return ( 
            <div>
            <MessengerCustomerChat
              pageId="688342724879971"
              appId="238303534077763"
              language ='es_MX'
            />
          </div>
         );
    }
}
 
export default Messenger;